// External dependencies
import { Div, Text } from 'atomize';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import { drugsFilters } from '@entities/drugs/lib/helper';
import { ListDrugsStatusFilter } from '@entities/drugs/lib/types';
import { listDrugs } from '@entities/drugs/redux/listDrug/actions';
import Button from '@shared/ui/button';
import Search from '@shared/ui/search/Search';
import Tabs from '@shared/ui/tab/Tabs';
import { navigate } from 'gatsby';

export default function ManagerControlBarDrugs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentPage, loading, filter, searchString, total } = useSelector(
    (state: RootState) => state.listDrugs,
  );

  const searchPlaceholderText = t('placeholderUserActionText');

  useEffect(() => {
    dispatch(listDrugs('', currentPage));
  }, []);

  function onSearch(e) {
    dispatch(listDrugs(e.target.value, currentPage));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function onSelectTab(status: ListDrugsStatusFilter) {
    dispatch(listDrugs(searchString, currentPage, { ...filter, status }));
  }

  function openCreateDrugForm() {
    navigate('/admin/drugs/create-drug');
  }

  return (
    <>
      <Text textSize="subtitle" textWeight="700" m={{ b: '20px' }}>
        {t('drugs')} ({loading ? '...' : total})
      </Text>
      <Div
        d="flex"
        align="center"
        style={{ gap: '30px' }}
        flexWrap="wrap"
      ></Div>
      <Div m={{ b: '20px' }} d="flex" align="center" justify="space-between">
        <Div d="flex" align="center" style={{ gap: '30px' }} flexWrap="wrap">
          <Div>
            <Search
              onChange={onSearch}
              placeholder={searchPlaceholderText}
              onClear={resetSearchField}
              value={searchString ? searchString : ''}
            />
          </Div>
          <Div>
            <Tabs
              items={drugsFilters}
              value={filter.status}
              onClick={onSelectTab}
              inputName="drug-status-filter"
            />
          </Div>
        </Div>
        <Div>
          <Button
            onClick={openCreateDrugForm}
            typeColor="primary"
            iconPrefix="Plus"
            textColor="white"
            className="create-drug__button"
          >
            {t('createDrug')}
          </Button>
        </Div>
      </Div>
    </>
  );
}
