// External dependensies
import { Div } from 'atomize';
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependensies
import { RootState } from '@app//redux/store';
import { listPrizes } from '@entities/prizes/redux/listPrizes/actions';
import Header from '@shared/ui/admin/header/Header';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { getDateTime } from '@shared/utils/renderDate';
import { UserRoles, getUserRole } from '@shared/utils/roles';
import DefaultAvatarIcon from '@svg/DefaultAvatarIcon';

import getDrugsFields from '@entities/drugs/lib/helper';
import { Drug } from '@entities/drugs/lib/types';
import ManagerControlBarDrugs from '@feautures/ui/drugs-controller/ManagerControlBarDrugs';
import { setLocalStorage } from '@shared/utils/helpers';

export default function ListDrugs() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { currentPage, loading, drugs, searchString, total } = useSelector(
    (state: RootState) => state.listDrugs,
  );

  const { t, language } = useI18next();

  function getImage(drug: Drug) {
    return (
      <>
        {drug?.avatar.length > 0 ? (
          <img
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            src={drug?.avatar}
            alt={drug?.name_en}
          />
        ) : (
          <DefaultAvatarIcon />
        )}
      </>
    );
  }

  function getDrugAndNavigateToDetails(id: string, __typeName: string) {
    setLocalStorage('drugId', id);
    setLocalStorage('drugTypename', __typeName);

    navigate('drug-details');
  }

  function onPageChange(page: number) {
    dispatch(listPrizes(searchString, page));
  }

  return (
    <Div m="0 auto" className="container">
      <Header />
      <Div p={{ t: '80px' }}>
        <Table
          items={drugs?.map((drug: Drug) => ({
            id: drug?.id,
            avatar: getImage(drug),
            title: drug[`name_${language}`],
            videoUrl: drug?.videoUrl,
            postDate: getDateTime(drug?.joinDate),
            status: `${drug?.status}`,
            description: drug[`description_${language}`],
          }))}
          onClickItem={getDrugAndNavigateToDetails}
          columns={getDrugsFields()}
          currentPage={currentPage}
          emptyMessage="noDrugs"
          controlBar={getControlBar()}
          loading={loading}
          onPageChange={onPageChange}
          stickyCol={1}
          searchString={''}
          selectionType={RowSelectionType.NUMBER}
          topBar={''}
          totalItemsCount={total}
        />
      </Div>
    </Div>
  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER): {
        return <ManagerControlBarDrugs />;
      }

      case userRole.includes(UserRoles.MEDUCATION_MANAGER): {
        return <ManagerControlBarDrugs />;
      }

      default:
        return null;
    }
  }
}
