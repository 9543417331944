// External dependecies
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Local dependencies
import { EntryStatus } from '@entities/users/lib/types';

export default function getDrugsFields() {
  const { t } = useTranslation();
  return [
    {
      field: 'id',
      title: t('ID'),
    },
    {
      field: 'avatar',
      title: t('Photo'),
    },
    {
      field: 'title',
      title: t('title'),
    },
    {
      field: 'videoUrl',
      title: t('videoLink'),
    },

    {
      field: 'postDate',
      title: t('joinDate'),
    },
    {
      field: 'status',
      title: t('status'),
    },
    {
      field: 'description',
      title: t('description'),
    },
  ];
}

export const drugsFilters = [
  {
    label: 'ALL',
    value: JSON.stringify('ALL'),
  },
  {
    label: EntryStatus.APPROVED,
    value: JSON.stringify(EntryStatus.APPROVED),
  },
  {
    label: EntryStatus.IN_REVIEW,
    value: JSON.stringify(EntryStatus.IN_REVIEW),
  },
  {
    label: EntryStatus.REJECTED,
    value: JSON.stringify(EntryStatus.REJECTED),
  },
];
